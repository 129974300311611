import React from "react";
import LoginContainer from "../components/pageComponents/Login/LoginContainer";
import styles from "../styles/Login.module.css";

const Login = () => {
    return(
    <div className={styles.mainContainer}>
        <LoginContainer />
    </div>)
}

export default Login;