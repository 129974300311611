import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './App.css';
import { Routes, Route  } from 'react-router';
import TopBar from './components/generalComponents/TopBar';
import SideBarMenu from './components/generalComponents/SideBarMenu';
import Articles from './pages/Articles';
import FooterCopyright from './components/generalComponents/FooterCopyright';
import 'devextreme/dist/css/dx.light.css';
import Editor from './components/generalComponents/Editor';
import EditArticle from './pages/EditArticle';
import TrashBin from './pages/TrashBin';
import Login from './pages/Login';
import { useEffect } from 'react';
import axios from 'axios';


export const BaseUrl = "https://morningview.developmentcitrine.space/backend/api";

//     headers:{
//       Authorization: token
//     }
//   }).then((response) => {
//     if(response.data.success == false) sessionStorage.removeItem('token');
//   }).catch((error) => {
//     sessionStorage.removeItem('token');
//     window.location.replace('/');
//   })
// }
function App() {
  const token = sessionStorage.getItem('token');
  useEffect(()=>{
    axios.get(BaseUrl+'/token-val',{

      headers:{
        Authorization: token
      }
    }).then((response) => {
      if(response.data.success == false) sessionStorage.removeItem('token');
    }).catch(() => {
      sessionStorage.removeItem('token');
    })
  },[token]);
  if(token == null || token === undefined || token == ''){
    return(<Login />);
  }else{
    return (
      <div>
        <TopBar />
        <div className="mainPanel">
          <SideBarMenu/>
            <div className="PanelContainer">
              <Routes>
                <Route path='/' element={<Articles/>} />
                <Route path='/articles' element={<Articles/>} />
                <Route path='/newArticle' element={<Editor material ={{title: ''}}/>} />
                <Route path='/editArticle' element={<EditArticle/>} />
                <Route path='/trashBin' element={<TrashBin />} />
              </Routes>
            </div>
        </div>
        
        
        <FooterCopyright/>
      </div>
    );
  }
  }
  

export default App;
