import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl, tokenVal } from "../App";
import TrashList from "../components/pageComponents/TrashBin/TrashList";

const TrashBin = () => {
    
    const [isLoading,setLoading] = useState(true);
    const [theTrash,setTrash] = useState([]);
    useEffect(() => {
        // tokenVal();
        axios.get(BaseUrl+'/get-trsh-bin').then((response) => {
                setLoading(false);
                setTrash(response.data.data);
                console.log(theTrash);
        })
    },[]);
    if(isLoading == false){
        return (
            <div>
                <TrashList allTrashes={theTrash}/>
            </div>
        );
    }else{
        return(<div>Loading . . .</div>)
    }
}

export default TrashBin;