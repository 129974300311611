import React, { Fragment, useEffect, useRef, useState } from 'react';
import 'devextreme/dist/css/dx.light.css';
import { HtmlEditor } from 'devextreme-react/html-editor';
import {Toolbar,Item,} from "devextreme-react/html-editor";
import { CheckBox } from 'devextreme-react/check-box';
import {MediaResizing} from "devextreme-react/html-editor";
import { Calendar } from 'devextreme-react/calendar';
import FileUploader from 'devextreme-react/file-uploader';
import styles from '../../styles/Editor.module.css'
import axios from 'axios';
import { BaseUrl } from '../../App';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { TextArea } from 'devextreme-react';

const sizeValues = [ "8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt" ];
const fontValues = [ "Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana" ];
const headerValues = [ false, 1, 2, 3, 4, 5 ];

const Editor = (props) => {
    const pathname = window.location.pathname;
    const [isTitle,setisTitle] = useState(props.material.title);
    const urlKey = useRef();
    const oldUrlKey = useRef();
    const title = useRef();
    const [date,setDate] = useState(new Date());
    const [tags,setTags] = useState([]);
    const [tag,setTag] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [firstPageFirstNews,setFirstPageFirstNews] = useState(props.material.first_page_first_news || false);
    const [firstPageFirstNews2,setFirstPageFirstNews2] = useState(props.material.first_page_first_news2 || false);
    const [firstPageFirstNews3,setFirstPageFirstNews3] = useState(props.material.first_page_first_news3 || false);
    const [image,setImage] = useState(null);
    const [markup,setMarkup] = useState('');
    const preview = useRef(props.material?.preview || '');
    const [borderRequired, setborderRequired] = useState(styles.borderRequired);
    const [titleError, settitleError] = useState(styles.titleError);
    const [bodyError, setbodyError] = useState(styles.titleError);
    const [tagError, settagError] = useState(styles.titleError);
    const [htmleditorreq, sethtmleditorreq] = useState({borderColor: 'lightgrey'});

    const handleChange = (e) => {
        settitleError(styles.titleError);
        setborderRequired(styles.borderRequired);
        sethtmleditorreq({borderColor: 'lightgrey'});
    }
    const handleMarkup = (e) => {
        setMarkup(e); 
        sethtmleditorreq({borderColor: 'lightgrey'}); 
        setbodyError(styles.titleError);
    }
    const createUpdateArticle = (id,title, body, date, tag, image, firstPageFirstNews, firstPageFirstNews2, firstPageFirstNews3, preview, urlKey, oldUrlKey) => {
        setValidationErrors([]);
        if(title.current.value === ""){
            setborderRequired(styles.borderRequiredTrue);
            settitleError(styles.titleErrorTrue);
            window.scroll(0,0);
        }
        if(markup === "") {
            sethtmleditorreq({borderColor: 'red'});
            setbodyError(styles.titleErrorTrue);
        }
        if(tag === null) {
            settagError(styles.titleErrorTrue);
        }
        
        else {
            date = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
            axios.post(BaseUrl+'/create-update-article',{
                    id: id,
                    releaseDate: date,
                    title: title.current.value,
                    tagId: tag,
                    body: body,
                    image: image,
                    first_page_first_news: firstPageFirstNews ? 1 : 0,
                    first_page_first_news2: firstPageFirstNews2 ? 1 : 0,
                    first_page_first_news3: firstPageFirstNews3 ? 1 : 0,
                    url_key: urlKey,
                    old_url_key: oldUrlKey,
                    preview: preview.current.value
            },{
                headers:{
                    'content-type': 'multipart/form-data'
                }
            }).then((response) => {
                if (response.data.success == true){
                    window.location.replace('/articles');
                }
            }).catch((err) => {
                if (err.response?.data?.success == false){
                    if (err.response?.data?.data) {
                        let tmpErrors = [];
                        for (let key in err.response.data.data) {
                            tmpErrors.push(err.response.data.data[key]);
                        }
                        setValidationErrors(tmpErrors);
                    }
                }
            });
        } 
    }

    useEffect(()=>{
        if(pathname == "/editArticle"){
            setMarkup(props.material.body);
            var artDate = new Date(props.material.releaseDate);
            setDate(artDate);
            setTag(props.material.tagId)
        }else {
            axios.get(BaseUrl+"/get-tags").then((response)=>{
                setTags(response.data.data);
            })
            setMarkup(``);
            setDate(new Date());
        }
    },[pathname])
    if(pathname == "/editArticle"){
        return (
            <div style={{marginBottom:'30px' }}>
                <div>
                    <label htmlFor="article_title">Τίτλος</label>
                    <input id="article_title" className={`${styles.addTitleInner} ${borderRequired}`} type="text" value={isTitle} onChange={e => {setisTitle(e.target.value); settitleError(styles.titleError);
        setborderRequired(styles.borderRequired);}} ref={title}/>
                    <div className={titleError}>Δεν έχετε εισάγει τίτλο.</div>
                </div>
                <div style={{marginTop: '30px'}}>
                    <label htmlFor="article_url_key">Κλειδί URL</label>
                    <input id="article_url_key" className={`${styles.addTitleInner}`} type='text' ref={urlKey} defaultValue={props.material?.url_key || ''} />
                </div>
                <div style={{marginTop: '30px'}}>
                    <label htmlFor="article_old_url_key">Παλιό κλειδί URL</label>
                    <input id="article_old_url_key" className={`${styles.addTitleInner}`} type='text' ref={oldUrlKey} defaultValue={props.material?.old_url_key || ''} />
                </div>
                {/* <div className={`${styles.addFile}`}>
                    <input  type='file' onChange={e=>setImage(e.target.files[0])} />
                </div>

                <div >
                <div className={styles.tagContainer}>{props.material.tag.description}</div>
                </div> */}

                <div className={styles.tagandimagediv}>
                    <div className={`${styles.addFile}`}>
                        <input type='file' onChange={e=>setImage(e.target.files[0])} />
                    </div>
                    {
                        (tag == 4) &&
                        <Fragment>
                            <div>
                                <CheckBox defaultValue={firstPageFirstNews} value={firstPageFirstNews} onValueChange={(v) => { setFirstPageFirstNews(v); if (v) { setFirstPageFirstNews2(false); setFirstPageFirstNews3(false); } } } />&nbsp;&nbsp;F1
                            </div>
                            <div>
                                <CheckBox defaultValue={firstPageFirstNews2} value={firstPageFirstNews2} onValueChange={(v) => { setFirstPageFirstNews2(v); if (v) { setFirstPageFirstNews(false); setFirstPageFirstNews3(false); } } } />&nbsp;&nbsp;F2
                            </div>
                            <div>
                                <CheckBox defaultValue={firstPageFirstNews3} value={firstPageFirstNews3} onValueChange={(v) => { setFirstPageFirstNews3(v); if (v) { setFirstPageFirstNews(false); setFirstPageFirstNews2(false); } } } />&nbsp;&nbsp;F3
                            </div>
                        </Fragment>
                    }
                    <div>
                    <Popup trigger={<button className={styles.Datebtn}> Επιλέξτε Ημερομηνία Δημοσίευσης</button>} position="right center">
                        <div className={`${styles.addDate}`}>
                            <Calendar defaultZoomLevel="month" showTodayButton={true} defaultValue={date}   onValueChange={e => setDate(e)} ></Calendar>
                        </div>
                    </Popup>
                    </div>
                    
                    <div className={styles.tagContainer}>
                        {/* <span>Επιλέξτε κατηγορία: </span> */}
                        <div>{props.material.author.description}</div>
                        
                    </div>
                </div>

                <HtmlEditor className="dx-htmleditor-content" style={htmleditorreq} value={markup} valueType="html" onValueChange={handleMarkup}>
                <Toolbar multiline={true}>
                        <Item name="undo" />
                        <Item name="redo" />
                        <Item name="separator" />
                        <Item name="size" acceptedValues={sizeValues} />
                        <Item name="font" acceptedValues={fontValues} />
                        <Item name="separator" />
                        <Item name="bold" />
                        <Item name="italic" />
                        <Item name="strike" />
                        <Item name="underline" />
                        <Item name="separator" />
                        <Item name="alignLeft" />
                        <Item name="alignCenter" />
                        <Item name="alignRight" />
                        <Item name="alignJustify" />
                        <Item name="separator" />
                        <Item name="orderedList" />
                        <Item name="bulletList" />
                        <Item name="separator" />
                        <Item name="header" acceptedValues={headerValues} />
                        <Item name="separator" />
                        <Item name="color" />
                        <Item name="background" />
                        <Item name="separator" />
                        <Item name="link" />
                        <Item name="image" />
                        <Item name="separator" />
                        <Item name="clear" />
                        <Item name="codeBlock" />
                        <Item name="blockquote" />
                        <Item name="separator" />
                        <Item name="insertTable" />
                        <Item name="deleteTable" />
                        <Item name="insertRowAbove" />
                        <Item name="insertRowBelow" />
                        <Item name="deleteRow" />
                        <Item name="insertColumnLeft" />
                        <Item name="insertColumnRight" />
                        <Item name="deleteColumn" />
                        <Item name="cellProperties" />
                        <Item name="tableProperties" />
                    </Toolbar>
                    <MediaResizing enabled={true} />
                </HtmlEditor>
                
                <div style={{marginTop: '30px'}}>
                    <label htmlFor="preview">Σύντομη περιγραφή</label>
                    <textarea id="preview" ref={preview} defaultValue={props.material?.preview || ''} className={`${styles.addTitleInner}`} rows={10} cols={25} maxLength={1500}></textarea>
                </div>

                <div className={bodyError}>Δεν έχετε εισάγει περιεχόμενο.</div>

                {/* <div className={`${styles.addDate}`}> <Calendar defaultZoomLevel="month" showTodayButton={true} defaultValue={date} onValueChange={e => setDate(e)}></Calendar></div> */}
                
                <div ><button className={`${styles.addArticle}`} onClick={()=>{createUpdateArticle(props.material.id,title,markup,date,tag,image,firstPageFirstNews,firstPageFirstNews2,firstPageFirstNews3,preview,urlKey.current.value,oldUrlKey.current.value)}}>Ενημέρωση άρθρου</button></div>
                <div className="errors">
                    {
                        validationErrors.map((err) => <p>{err}</p>)
                    }
                </div>
            </div>);
    }else{
        return(
            <div style={{marginBottom:'30px' }}>
                <div>
                    <label htmlFor="article_title">Τίτλος</label>
                    <input id="title" className={`${styles.addTitleInner} ${borderRequired}`} type="text" placeholder='Προσθήκη τίτλου...' ref={title} onChange={handleChange}/>
                    <div className={titleError}>Δεν έχετε εισάγει τίτλο.</div>
                </div>
                <div style={{marginTop: '30px'}}>
                    <label htmlFor="article_url_key">Κλειδί URL</label>
                    <input id="article_url_key" className={`${styles.addTitleInner}`} type='text' ref={urlKey} defaultValue={props.material?.url_key || ''} />
                </div>
                <div style={{marginTop: '30px'}}>
                    <label htmlFor="article_old_url_key">Παλιό κλειδί URL</label>
                    <input id="article_old_url_key" className={`${styles.addTitleInner}`} type='text' ref={oldUrlKey} defaultValue={props.material?.old_url_key || ''} />
                </div>
                <div className={styles.tagandimagediv}>
                    <div className={`${styles.addFile}`}>
                        <input type='file' onChange={e=>setImage(e.target.files[0])} />
                    </div>
                    {
                        (tag == 4) &&
                        <Fragment>
                            <div>
                                <CheckBox defaultValue={firstPageFirstNews} value={firstPageFirstNews} onValueChange={(v) => { setFirstPageFirstNews(v); if (v) { setFirstPageFirstNews2(false); setFirstPageFirstNews3(false); } } } />&nbsp;&nbsp;F1
                            </div>
                            <div>
                                <CheckBox defaultValue={firstPageFirstNews2} value={firstPageFirstNews2} onValueChange={(v) => { setFirstPageFirstNews2(v); if (v) { setFirstPageFirstNews(false); setFirstPageFirstNews3(false); } } } />&nbsp;&nbsp;F2
                            </div>
                            <div>
                                <CheckBox defaultValue={firstPageFirstNews3} value={firstPageFirstNews3} onValueChange={(v) => { setFirstPageFirstNews3(v); if (v) { setFirstPageFirstNews(false); setFirstPageFirstNews2(false); } } } />&nbsp;&nbsp;F3
                            </div>
                        </Fragment>
                    }
                    <div>
                    <Popup trigger={<button className={styles.Datebtn}> Επιλέξτε Ημερομηνία Δημοσίευσης</button>} position="right center">
                        <div className={`${styles.addDate}`}>
                            <Calendar defaultZoomLevel="month" showTodayButton={true} defaultValue={date}   onValueChange={e => setDate(e)} ></Calendar>
                        </div>
                    </Popup>
                    </div>
                    
                    <div className={styles.tagContainer}>
                        {/* <span>Επιλέξτε κατηγορία: </span> */}
                        <select onChange={e => {setTag(e.target.options[e.target.selectedIndex].getAttribute('tagid')); settagError(styles.titleError); setFirstPageFirstNews(e.target.options[e.target.selectedIndex].getAttribute('tagId') == '4' ? firstPageFirstNews : false); setFirstPageFirstNews2(e.target.options[e.target.selectedIndex].getAttribute('tagId') == '4' ? firstPageFirstNews2 : false); setFirstPageFirstNews3(e.target.options[e.target.selectedIndex].getAttribute('tagId') == '4' ? firstPageFirstNews3 : false); } }>
                            <option selected disabled>Επιλέξτε κατηγορία:</option>
                            {tags.map((t) => {
                                return(<option key={t.id} tagId={t.id}>{t.description}</option>);
                                })
                            }
                        </select>
                        <div className={tagError}>Επιλέξτε κατηγορία.</div>
                    </div>
                </div>

                <HtmlEditor className="dx-htmleditor-content" style={htmleditorreq} defaultValue={markup} valueType="html" onValueChange={handleMarkup}>
                <Toolbar multiline={true}>
                        <Item name="undo" />
                        <Item name="redo" />
                        <Item name="separator" />
                        <Item name="size" acceptedValues={sizeValues} />
                        <Item name="font" acceptedValues={fontValues} />
                        <Item name="separator" />
                        <Item name="bold" />
                        <Item name="italic" />
                        <Item name="strike" />
                        <Item name="underline" />
                        <Item name="separator" />
                        <Item name="alignLeft" />
                        <Item name="alignCenter" />
                        <Item name="alignRight" />
                        <Item name="alignJustify" />
                        <Item name="separator" />
                        <Item name="orderedList" />
                        <Item name="bulletList" />
                        <Item name="separator" />
                        <Item name="header" acceptedValues={headerValues} />
                        <Item name="separator" />
                        <Item name="color" />
                        <Item name="background" />
                        <Item name="separator" />
                        <Item name="link" />
                        <Item name="image" />
                        <Item name="separator" />
                        <Item name="clear" />
                        <Item name="codeBlock" />
                        <Item name="blockquote" />
                        <Item name="separator" />
                        <Item name="insertTable" />
                        <Item name="deleteTable" />
                        <Item name="insertRowAbove" />
                        <Item name="insertRowBelow" />
                        <Item name="deleteRow" />
                        <Item name="insertColumnLeft" />
                        <Item name="insertColumnRight" />
                        <Item name="deleteColumn" />
                        <Item name="cellProperties" />
                        <Item name="tableProperties" />
                    </Toolbar>
                    <MediaResizing enabled={true} />
                </HtmlEditor>
                
                <div style={{marginTop: '30px'}}>
                    <label htmlFor="preview">Σύντομη περιγραφή</label>
                    <textarea id="preview" ref={preview} defaultValue={props.material?.preview || ''} className={`${styles.addTitleInner}`} rows={10} cols={25} maxLength={1500}></textarea>
                </div>
                
                <div className={bodyError}>Δεν έχετε εισάγει περιεχόμενο.</div>

                <div><button className={`${styles.addArticle}`} onClick={()=>{return createUpdateArticle(0,title,markup,date,tag,image,firstPageFirstNews,firstPageFirstNews2,firstPageFirstNews3,preview,urlKey.current.value,oldUrlKey.current.value)}}>Προσθήκη άρθρου</button></div>
                <div className="errors">
                    {
                        validationErrors.map((err) => <p>{err}</p>)
                    }
                </div>
            </div>
            
        );
    }
        
}
 
export default Editor;