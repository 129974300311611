import { useEffect, useState } from "react";
import { BaseUrl, tokenVal } from '../App';
import axios from "axios";
import ArticlesContainer from "../components/pageComponents/Articles/ArticlesContainer";
import { useLocation } from "react-router";

const Articles = () => {
    const search = useLocation();
    const tag = search.state;
    var theTagId = tag == null ? null : tag.tagId
    const [isLoading,setLoading] = useState(true);
    const [article,setArticle] = useState([]);
    
    useEffect(()=>{
        axios.get(BaseUrl+'/get-articles-admin',{
            params:{
                tagId:theTagId
            }
        }).then((response) => {
            setArticle(response.data.data);
            setLoading(false);
            
        });
    }, [theTagId])

    return (
        <div onChange={()=>{window.reload()}}>
            <ArticlesContainer allarticles = {article} />
        </div>
    )
}

export default Articles;